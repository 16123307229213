import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, tap, ReplaySubject, map, switchMap, of } from 'rxjs';
import { toODataString, State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/service/util.service';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  constructor(private httpClient: HttpClient, private utilService: UtilService) {}

  private BASE_URL = `${environment.apiUrl}`;

  public CarrierInvoiceSummary: ReplaySubject<any> = new ReplaySubject<any>(1);

  odata_CarrierInvoiceSummary(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}CarrierShipmentInvoicesSummary?${toODataString(_state)}&$count=true`;
    return this.httpClient.get<any>(uri).pipe(
      tap((response: any) => {
        this.CarrierInvoiceSummary.next(response);
      })
    );
  }
}
