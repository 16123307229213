import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { MaterialModule } from './material.module';
import { KendoModule } from './kendo.module';
import { FormatDatePipe } from 'src/app/pipe/format-date.pipe';

@NgModule({
  declarations: [FormatDatePipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, KendoModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, KendoModule, FormatDatePipe],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class SharedModule {}
