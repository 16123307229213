import { NgModule } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import '@progress/kendo-angular-intl/locales/fr-CA/all';

@NgModule({
  imports: [ButtonsModule, DateInputsModule, DialogsModule, DropDownsModule, GridModule, InputsModule, LabelModule, PopoverModule, ExcelModule],
  exports: [ButtonsModule, DateInputsModule, DialogsModule, DropDownsModule, GridModule, InputsModule, LabelModule, PopoverModule, ExcelModule],
})
export class KendoModule {}
