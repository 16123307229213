<div class="globalPopupClass">
  <div class="MainTitleSection">
    <mat-icon style="align-self: center">{{ mainIcon }}</mat-icon>
    <h3 style="padding-left: 20px; align-self: center">{{ titre }}</h3>
  </div>
  <mat-dialog-content><p [innerHTML]="texte"></p></mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="showDismissiveButton" mat-stroked-button style="flex: 50" color="{{ dismissiveActionColor }}" mat-dialog-close>
      {{ dismissiveActionName }}
    </button>
    <button *ngIf="showAffirmativeButton" mat-flat-button style="flex: 50" color="{{ affirmativeActionColor }}" [mat-dialog-close]="true">
      {{ affirmativeActionName }}
    </button>
  </mat-dialog-actions>
</div>
