/**
 * Interface représentant les permissions que peuvent avoir les utilisateurs du système.
 */
export interface UserRights {
  isAnonymous: boolean; // l'utilisateur peut consulter une page autre que la page /logByPin
  scan1: boolean; // l'usager à le droit d'utiliser le scan 1
  scan2Emballeur: boolean; // l'usager à le droit d'utiliser le scan 2
  onlineOrders: boolean;
  reception: boolean;
  globalAdmin: boolean;
  sysAdmin: boolean;
  financeAdmin: boolean;
  scan2EmballeurChangerDVL: boolean; //permet à un usager, dans le scan2 emballeur, de changer la DVL en cours
  scan2EmballeurChangerBoite: boolean; //permet à un usager, dans le scan2 emballeur, de modifier la boîte à suggérer pour un produit
  achatLabels: boolean; // permet à un usager d'accéder à l'interface de calcul et d'achat d'étiquettes d'expédition
}

/**
 * Constante contenant une liste des permissions de l'interface. Est utilisé pour l'alimentation automatique
 * de l'interface à partir de la réponse de l'API.  à faire MATCHER avec l'API (en fait avec projet 145B..SecurityHelperClass.cs, où l'on va trouver la liste des CONSTANTES aussi.  faire matcher le VALUE)
 */
export const listofUserRights: ReadonlyArray<string> = [
  'isAnonymous',
  'scan1',
  'scan2Emballeur',
  'onlineOrders',
  'reception',
  'globalAdmin',
  'sysAdmin',
  'financeAdmin',
  'scan2EmballeurChangerDVL',
  'scan2EmballeurChangerBoite',
  'achatLabels',
];
