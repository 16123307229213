import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-confirm',
  templateUrl: './pop-confirm.component.html',
  styleUrls: ['./pop-confirm.component.css'],
})
export class PopConfirmComponent {
  titre: string = '';
  texte: string = '';

  showAffirmativeButton: boolean = true;
  affirmativeActionName: string = 'Oui';
  affirmativeActionColor: string = 'primary';

  showDismissiveButton: boolean = true;
  dismissiveActionName: string = 'Non';
  dismissiveActionColor: string = '';

  mainIcon: string = 'information'; //https://fonts.google.com/icons  enMINUSCULE-  //warning:beau triangle,

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data?.titre) this.titre = data.titre;
    if (data?.texte) this.texte = data.texte;
    if (data?.affirmativeActionName) this.affirmativeActionName = data.affirmativeActionName;
    if (data?.affirmativeActionColor) this.affirmativeActionColor = data.affirmativeActionColor;
    if (data?.dismissiveActionName) this.dismissiveActionName = data.dismissiveActionName;
    if (data?.dismissiveActionColor) this.dismissiveActionColor = data.dismissiveActionColor;
    if (data?.showDismissiveButton === false) {
      this.showDismissiveButton = false;

      //si on demande à CACHER le bouton DISMISSIVE,  mais qu'on a pas spécifié de nom pour le bouton AFFIRMATIVE, on va prendre le nom 'OK'
      if (data?.affirmativeActionName == undefined) this.affirmativeActionName = 'OK';
    }
    if (data?.showAffirmativeButton === false) {
      this.showAffirmativeButton = false;
    }
    if (data?.mainIcon) this.mainIcon = data.mainIcon;
  }
}
