import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { State, toODataString } from '@progress/kendo-data-query';
import { UtilService } from '../../service/util.service';

@Injectable({
  providedIn: 'root',
})
export class SystemManagerService {
  constructor(private httpclient: HttpClient, private utilService: UtilService) {}

  BASE_URL = `${environment.apiUrl}`;

  //permet d'aller chercher une liste des systemName et SubsystemName
  getListLogExecSystemNames(): Observable<any> {
    return this.httpclient.get<any>(`${this.BASE_URL}SystemLogExecution?$apply=groupby((SystemName,SubsystemName))&top=10&$count=true`);
  }
  //permet d'aller chercher une liste des systemName et SubsystemName
  getListBatchExecSystemNames(): Observable<any> {
    return this.httpclient.get<any>(`${this.BASE_URL}BatchExecutions?$apply=groupby((SystemName,SubsystemName))&top=10&$count=true`);
  }

  getApiInfo(): Observable<any> {
    return this.httpclient.get<any>(`${this.BASE_URL}SystemManager/GetBasicApiInfo`);
  }

  //**********************************************************/
  //#region BatchExecution - Détails
  /** ReplaySubject des batchExecutions */
  public BatchExecutionDetails: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**Fonction permettant d'aller chercher les BatchExecutions [le détail] */
  odata_BatchExecutionDetails(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}BatchExecutions?${toODataString(_state)}&$count=true`;
    return this.httpclient.get<any>(uri).pipe(
      tap((response: any) => {
        this.BatchExecutionDetails.next(response);
      })
    );
  }
  //#endregion
  //**********************************************************/

  //**********************************************************/
  //#region BatchExecution - Sommaire
  /** ReplaySubject des batchExecutions */
  public BatchExecutionSummary: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**Fonction permettant d'aller chercher les BatchExecutions [le détail] */
  FetchBatchExecutionSummary_NonOdata(): Observable<any> {
    let uri = `${this.BASE_URL}SystemManager/BatchExecutions/Getvw_Batch_Summary?$orderby=PriorityOfThisTask`;
    return this.httpclient.get<any>(uri).pipe(
      tap((response: any) => {
        this.BatchExecutionSummary.next(response);
      })
    );
  }

  //#endregion
  //**********************************************************/

  //**********************************************************/
  //#region BatchExecution - Sommaire
  /** ReplaySubject des batchExecutions */
  public LogExecutionSummary: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**Fonction permettant d'aller chercher les BatchExecutions [le détail] */
  FetchLogExecutionSummary_NonOdata(state: State): Observable<any> {
    let uri = `${this.BASE_URL}SystemLogExecution/Getvw_Logs_Summary`;
    return this.httpclient.get<any>(uri).pipe(
      tap((response: any) => {
        this.LogExecutionSummary.next(response);
      })
    );
  }

  //#endregion
  //**********************************************************/

  //**********************************************************/
  //#region BatchExecution - Détails
  /** ReplaySubject des batchExecutions */
  public LogExecutionDetails: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**Fonction permettant d'aller chercher les BatchExecutions [le détail] */
  odata_LogExecutionDetails(state: State): Observable<any> {
    let _state = this.utilService.makeStateCaseInsensitive(state);
    let uri = `${this.BASE_URL}SystemLogExecution?${toODataString(_state)}`;
    return this.httpclient.get<any>(uri).pipe(
      tap((response: any) => {
        this.LogExecutionDetails.next(response);
      })
    );
  }
  //#endregion
  //**********************************************************/
}
