/**
 * Liste des clé Window.localStorage utilisées dans l'application.
 */
export enum LocalStorage {
  SCAN1_HEADER_ID = 'Scan1_headerID',
  JWT = 'JWT-Content',
  JWT_SOURCE_SERVER = 'jwt_source_server',
  LAST_STANDARD_SUCCESSFUL_LOGIN_JWT = 'last_standard_successful_login_jwt',
  SCAN_CURRENT_ITEM = 'Scan_current_Item',
  SCAN_TYPE_CURRENT_MODE = 'scan_type_current_mode',
  SCAN_CURRENT_STATION_NAME = 'scan_current_station_name',
  SIDENAV_VISIBILITE = 'sidenav_visibilite',
  PAGINATION_ROW_PER_PAGE = 'pagination_row_per_page',
  LOGIN_REMEMBER_ME = 'login_remember_me',
  LAST_LOGIN_CODE = 'last_login_code',
  LOGIN_PREFERED_METHOD = 'login_prefered_method',
}
