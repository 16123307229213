import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PopConfirmComponent } from '../component/core/pop-confirm/pop-confirm.component';
import { HttpErrorMsg } from '../models/HttpErrorMsg';

@Injectable({
  providedIn: 'root',
})

/**
 * Ce service permet de simplifier l'usage du PopConfirmComponent.
 */
export class PopUpDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * httpErrorPopup - permet d'avoir un pop-up auto généré suite à une erreur HTTP
   * @param myHtmlError c'est ici l'erreur levé par l'interceptor http.
   */
  httpErrorPopup(myHtmlError: HttpErrorMsg) {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: myHtmlError.titreErreur,
        texte: myHtmlError.messageErreurDetail,
        mainIcon: 'warning',
        showDismissiveButton: false,
        affirmativeActionName: 'OK',
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * SimpleAlert - permet d'afficher un simple pop-up du type 'Alert' de javascript
   * @param texte c'est le texte principal à metter dans le pop-up.  par défaut, vide.
   * @param titre c'est le titre du pop-up.  par défaut : 'Attention'.
   * @param MyIcon Icon de materuial qu'on trouuve ici : https://fonts.google.com/icons?icon.query=warning.  par defaut, warning.
   *
   */
  SimpleErrorAlert(texte: string = '', titre: string = 'Attention', MyIcon: string = 'warning', affirmativeActionColor: string = 'warn'): Promise<any> {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: titre,
        texte: texte,
        mainIcon: MyIcon,
        showDismissiveButton: false,
        affirmativeActionName: 'OK',
        affirmativeActionColor: affirmativeActionColor,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * ConfirmDialog - permet d'afficher un dialog oui/non (affirmative/dismissive)
   * @param texte c'est le texte principal à metter dans le pop-up.  par défaut, vide.
   * @param titre c'est le titre du pop-up.  par défaut : 'Attention'.
   * @param MyIcon Icon de materuial qu'on trouuve ici : https://fonts.google.com/icons?icon.query=warning.  par defaut, warning.
   *
   */
  ConfirmDialog(
    texte: string = '',
    titre: string = 'Attention',
    MyIcon: string = 'warning',
    affirmativeActionName: string = 'Oui',
    dismissiveActionName: string = 'Non'
  ): Promise<any> {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: {
        titre: titre,
        texte: texte,
        mainIcon: MyIcon,
        affirmativeActionName: affirmativeActionName,
        dismissiveActionName: dismissiveActionName,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }

  /**
   * ConfirmDialog - permet d'afficher un dialog oui/non (affirmative/dismissive)
   * @param texte c'est le texte principal à metter dans le pop-up.  par défaut, vide.
   * @param titre c'est le titre du pop-up.  par défaut : 'Attention'.
   * @param MyIcon Icon de materuial qu'on trouuve ici : https://fonts.google.com/icons?icon.query=warning.  par defaut, warning.
   *
   */
  FullDialog(data: any): Promise<any> {
    const dialogRef = this.dialog.open(PopConfirmComponent, {
      data: data,
    });
    return dialogRef.afterClosed().toPromise();
  }
}
