import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { listofUserRights, UserRights } from '../models/user/userRights';
import { UserInfo } from '../models/user/UserInfo';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorage } from '../models/enum/LocalStorage';
import { ApiPostLogin } from '../models/API/ApiPostLogin';
import { PopUpDialogService } from './popUpDialog.service';
import { HttpErrorMsg } from '../models/HttpErrorMsg';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  CurrentLoginPage: string = 'logByPin';

  get rights(): UserRights {
    return this.Xrights;
  }

  get info(): UserInfo {
    return this.Xinfo;
  }

  isLogged: boolean;
  userId: string;

  constructor(private httpClient: HttpClient, private router: Router, private popupService: PopUpDialogService) {}

  // Liste des permissions de l'utilisateur
  // on utilise une interface pour s'assurer que l'objet soit bien remplis.
  // Est en lecture seule.
  private Xrights: UserRights = {
    isAnonymous: true,
    scan1: true,
    scan2Emballeur: true,
    onlineOrders: true,
    reception: true,
    globalAdmin: true,
    sysAdmin: false,
    financeAdmin: false,
    scan2EmballeurChangerDVL: false,
    scan2EmballeurChangerBoite: false,
    achatLabels: false,
  };

  private Xinfo: UserInfo = {
    urlSmiley: '',
    name: '',
    stationCode: 'AUCUNE_SÉLECTIONNÉE',
    UserSessionID: 0,
  };

  /**
   * Permet de vérifier que le login est toujours valide afin d'éviter un 401.
   * Si expiré, on retourne un Observable d'erreur.
   * Si valide, on retourne null.
   */
  static checkIfLoginExpired(): Observable<never> | null {
    const helper = new JwtHelperService();
    if (helper.isTokenExpired(localStorage.getItem(LocalStorage.JWT))) {
      return throwError(() => new Error('Your session is expired. Please login again.'));
    }

    return null;
  }

  /**
   * Permet de vérifier que le JWT du user a bien été émis par le serveur présent
   * Évite de pouvoir passer de STAGING à PROD sans s'authentifier
   * Si différent, on retourne un Observable d'erreur.
   * Si identique, on retourne null.
   */
  static checkIfSameEnvironment(): Observable<never> | null {
    if (localStorage.getItem(LocalStorage.JWT_SOURCE_SERVER) !== environment.apiUrl) {
      return throwError(() => new Error('Your session is expired. Please login again.'));
    }

    return null;
  }

  async Login(username: string, password: string) {
    try {
      var LogTask = await this.httpClient
        .post(environment.apiUrl + 'Auth/login', { Username: username, Password: password }, { responseType: 'text' })
        .toPromise();
      const helper = new JwtHelperService();
      const decodedToken: ApiPostLogin = helper.decodeToken(LogTask); // on rend le xxx.xxx.xxx en obj lisible
      this.userId = decodedToken.UserID;
      localStorage.setItem(LocalStorage.JWT, LogTask); // on stocke le JWT dans la mémoire du navigateur
      localStorage.setItem(LocalStorage.LAST_STANDARD_SUCCESSFUL_LOGIN_JWT, LogTask); // Sera gardé en mémoire pour autoriser le logByPin
      localStorage.setItem(LocalStorage.JWT_SOURCE_SERVER, environment.apiUrl); // On stocke l'URL du serveur émetteur du JWT
      this.isLogged = true;
      this.SetPermissions(decodedToken.user_roles); // on met à jour les permissions
      this.Xinfo.name = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']; // maj nom
      this.router.navigate(['chooseWorkstation', 'accueil']);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Appelle l'API afin de s'authentifier puis met à jour les données de l'application telles que les permissions ou
   * le nom de l'utilisateur.
   * @param PinCode NIP de l'utilisateur
   */
  LogByPin(PinCode: string): void {
    var LastStandardSuccessfulLoginJwt = localStorage.getItem(LocalStorage.LAST_STANDARD_SUCCESSFUL_LOGIN_JWT);
    this.httpClient.post(environment.apiUrl + 'Auth/LoginByPin', { PinCode, LastStandardSuccessfulLoginJwt }, { responseType: 'text' }).subscribe({
      next: (data) => {
        const helper = new JwtHelperService();
        const decodedToken: ApiPostLogin = helper.decodeToken(data); // on rend le xxx.xxx.xxx en obj lisible

        localStorage.setItem(LocalStorage.JWT, data); // on stocke le JWT dans la mémoire du navigateur
        localStorage.setItem(LocalStorage.JWT_SOURCE_SERVER, environment.apiUrl); // On stocke l'URL du serveur émetteur du JWT
        this.userId = decodedToken.UserID;
        this.isLogged = true;
        this.SetPermissions(decodedToken.user_roles); // on met à jour les permissions
        this.Xinfo.name = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']; // maj nom
        this.router.navigate(['chooseWorkstation', 'accueil']);
      },
      error: (error: HttpErrorMsg) => {
        if (error.statusCode === 404) {
          this.popupService.SimpleErrorAlert('Le code PIN soumit ne semble pas correspondre à aucun usager.<br/><br/>', 'Code pin invalide');
        } else if (error.messageErreurDetail.includes('"detail":"LastJWT invalide"')) {
          let navigationExtras: NavigationExtras = { state: { data: 'LastJWT invalide' } };
          this.router.navigate(['regularLogin'], navigationExtras);
        } else {
          this.popupService.httpErrorPopup(error);
        }
        console.error(error);
      },
    });
  }

  /**
   * Permet à l'opétateur de se déconnecter.
   */
  async logout(RedirectToLogByPin: boolean = true) {
    try {
      var result = await this.httpClient.post(environment.apiUrl + 'Auth/logout', {}).toPromise();
    } catch (error) {}

    try {
      // retrait du JWT
      localStorage.removeItem(LocalStorage.JWT);
      // Oubli de la station utilisée
      localStorage.setItem(LocalStorage.SCAN_CURRENT_STATION_NAME, 'AUCUNE_SÉLECTIONNÉE');
      this.isLogged = false;
      // Remplacement par un user « par défaut »
      this.Xinfo = {
        name: 'anonymous',
        urlSmiley: '',
        stationCode: 'AUCUNE_SÉLECTIONNÉE',
        UserSessionID: 0,
      };
    } catch (error) {
      throw error;
    }
    // Retrait de toutes les permissions
    this.Xrights = {
      isAnonymous: false,
      scan1: false,
      scan2Emballeur: false,
      onlineOrders: false,
      reception: false,
      globalAdmin: false,
      sysAdmin: false,
      financeAdmin: false,
      scan2EmballeurChangerDVL: false,
      scan2EmballeurChangerBoite: false,
      achatLabels: false,
    };

    this.router.navigate(['/' + this.CurrentLoginPage]); // retourne sur la page d'authentification.
  }

  /**
   * Met à jour les permissions.
   * @param permissions string des permissions, séparées par une virgule.
   * @private
   */
  private SetPermissions(permissions: string): void {
    //on valide si il y a quelque choses dans les permissions
    if (permissions && permissions.length > 0) {
      const apiPermissions = permissions.split(',');
      listofUserRights.forEach((right) => {
        //si le string de droit n'est pas vide...
        if (right && right.length > 0) this.Xrights[right] = apiPermissions.includes(right);
      });
    }
  }

  /**
   * Met à jour les variables globales d'authentification.
   */
  hydrate(): void {
    const helper = new JwtHelperService();
    const decodedToken: ApiPostLogin = helper.decodeToken(localStorage.getItem(LocalStorage.JWT)); // on rend le xxx.xxx.xxx en obj lisible
    if (decodedToken) {
      this.isLogged = true;
      this.SetPermissions(decodedToken.user_roles); // on met à jour les permissions
      this.Xinfo.name = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']; // maj nom
      let tempUserSessionID = decodedToken['UserSessionID']; // maj nom
      if (isNaN(tempUserSessionID) === false) {
        this.Xinfo.UserSessionID = +tempUserSessionID;
      }
      this.Xinfo.stationCode = localStorage.getItem(LocalStorage.SCAN_CURRENT_STATION_NAME);
    } else {
      this.logout();
    }
  }
}
