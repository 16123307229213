{
  "name": "mdpweb-cerveau-webapp",
  "version": "2.4.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/localize": "^18.0.0",
    "@angular/material": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@auth0/angular-jwt": "^5.2.0",
    "@progress/kendo-angular-buttons": "^16.0.0",
    "@progress/kendo-angular-common": "^16.0.0",
    "@progress/kendo-angular-dateinputs": "^16.0.0",
    "@progress/kendo-angular-dialog": "^16.0.0",
    "@progress/kendo-angular-dropdowns": "^16.0.0",
    "@progress/kendo-angular-excel-export": "^16.0.0",
    "@progress/kendo-angular-grid": "^16.0.0",
    "@progress/kendo-angular-inputs": "^16.0.0",
    "@progress/kendo-angular-intl": "^16.0.0",
    "@progress/kendo-angular-l10n": "^16.0.0",
    "@progress/kendo-angular-label": "^16.0.0",
    "@progress/kendo-angular-pdf-export": "^16.0.0",
    "@progress/kendo-angular-popup": "^16.0.0",
    "@progress/kendo-angular-tooltip": "^16.0.0",
    "@progress/kendo-angular-treeview": "^16.0.0",
    "@progress/kendo-data-query": "^1.7.0",
    "@progress/kendo-drawing": "^1.20.1",
    "@progress/kendo-licensing": "^1.3.5",
    "@progress/kendo-theme-default": "^8.0.1",
    "rxjs": "^7.0.0",
    "tslib": "^2.3.0",
    "xlsx": "^0.17.4",
    "zone.js": "^0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.1",
    "@angular/cli": "^18.0.1",
    "@angular/compiler-cli": "^18.0.0",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^12.20.55",
    "jasmine-core": "~4.0.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "typescript": "~5.4.5"
  }
}
