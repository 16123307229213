import { Pipe, PipeTransform } from '@angular/core';

/**Ce pipe permet de transformer une date dans un format qui nous intéresse [D:yyyy-MM-dd,  DT:yyyy-MM-dd HH:mm:ss] */
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  /**Ce pipe permet de transformer une date dans un format qui nous intéresse [D:yyyy-MM-dd,  DT:yyyy-MM-dd HH:mm:ss]  */
  transform(value: string, ...arg: any): string {
    if (!value) return null;
    else if (arg == 'D')
      // date
      return value.split('T')[0];
    else if (arg == 'DT') {
      // date & temps
      return value.split('T')[0] + ' ' + value.substr(11, 8);
    }
    return value;
  }
}
